import React, { useState,useEffect } from "react";
import { useDispatch } from 'react-redux';
import { clearOrder } from '../features/orderSlice';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import api from '../utils/api'

const PaymentForm = () => {
  const [paymentError, setPaymentError] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const order = useSelector((state) => state.order); 
  console.log("order =>", order);
  const productDetails = order.productDetails || [];
  // const priceDetails = order?.productDetails?.reduce(
  //   (acc, product) => {
  //     const productPrice = product?.price || 0; // Default to 0 if price is missing
  //     const productQuantity = product?.quantity || 1; // Default to 1 if quantity is missing
  //     const productSubtotal = productPrice * productQuantity;

  //     acc.subtotal += productSubtotal;
  //     return acc;
  //   },
  //   {
  //     subtotal: 0,
  //     discount: 10, // Example discount applied to the entire order
  //     deliveryCharges: 50, // Example delivery charge for the entire order
  //     total: 0
  //   }
  // );
  useEffect(() => {
    if (productDetails.length === 0) {
      navigate('/products');  // Automatically redirect if there are no products
    }
  }, [productDetails, navigate]);
  const priceDetails = productDetails.reduce(
    (acc, product) => {
      const productPrice = product?.price || 0;
      const productQuantity = product?.quantity || 1;
      const productSubtotal = productPrice * productQuantity;

      acc.subtotal += productSubtotal;
      return acc;
    },
    {
      subtotal: 0,
      discount: 10, // Example discount applied to the entire order
      deliveryCharges: 50, // Example delivery charge for the entire order
      total: 0,
    }
  );
  // Add discount and delivery charges to total
  // priceDetails.total = priceDetails.subtotal - priceDetails.discount + priceDetails.deliveryCharges;
  // console.log("priceDetails.total =>", priceDetails.total);


  // Add discount and delivery charges to total
  priceDetails.total = priceDetails.subtotal - priceDetails.discount + priceDetails.deliveryCharges;

  // Handle empty order state
  if (productDetails.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <h2 className="text-2xl font-bold mb-4">No items in the order.</h2>
        <button
          onClick={() => navigate("/products")}
          className="bg-blue-600 text-white py-2 px-6 rounded-lg hover:bg-blue-500"
        >
          Continue Shopping
        </button>
      </div>
    );
  }
  const handlePayment = async () => {
    let userData = JSON.parse(localStorage.getItem('user'))
    console.log("userData =>",userData);
    
    // userData = 
    // localStorage.setItem("user",JSON.parse(response.data.user))
    // const orderDetails = {
    //   orderItems: order.productDetails,
    //   shippingAddress: order.shippingAddress,
    //   paymentMethod: "razorpay",
    // };
    const orderDetails = {
      user: userData._id, // Ensure the userId is captured
      orderItems: order.productDetails.map((product) => ({
        name: product.name,
        quantity: product.quantity,
        image: product.selectedImage,
        price: product.price,
        product: product._id, // Assuming product._id is the ObjectId of the product
      })),
      shippingAddress: order.shippingAddress,
      paymentMethod: "razorpay",
      itemsPrice: priceDetails.subtotal,
      shippingPrice: priceDetails.deliveryCharges,
      totalPrice: priceDetails.total, // Ensure total price is calculated properly
      isPaid: true, // Set to true after payment verification
      paidAt: new Date().toISOString() // Capture current timestamp
    };
  
    try {
      console.log("priceDetails =>", priceDetails.total);
  
      // Create Razorpay order
      const razorpayOrderResponse = await api.post(
        `${process.env.REACT_APP_API_URL}/api/orders/create-razorpay-order`,
        { amount: priceDetails.total }, // Data sent in the request body
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("token")}`, // Add authorization token
          },
        }
      );
      
      console.log("razorpayOrderResponse =>", razorpayOrderResponse);
      const { razorpayOrder } = razorpayOrderResponse.data; // Use .data for axios response
      console.log("razorpayOrder =>", razorpayOrder);
  
      const options = {
        key: "rzp_test_ivWzdPIycsYIPZ",
        amount: razorpayOrder.amount,
        currency: razorpayOrder.currency,
        name: "Saree Grace",
        description: "Purchase Description",
        order_id: razorpayOrder.id, // Razorpay Order ID
        handler: async (response) => {
          // Payment verification
          try {
            const paymentVerificationResponse = await api.post(
              `${process.env.REACT_APP_API_URL}/api/orders/verify-payment`,
              {
                paymentId: response.razorpay_payment_id,
                orderId: razorpayOrder.id, // Razorpay order ID
                signature: response.razorpay_signature,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );
  
            console.log("paymentVerificationResponse =>", paymentVerificationResponse);
            console.log(" orderDetails =>",orderDetails);
            
  
            // After verification, create the order in your database
            const orderRecordResponse = await api.post(
              `${process.env.REACT_APP_API_URL}/api/orders/create`,
              orderDetails,
              {
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );
  
            console.log("orderRecordResponse =>", orderRecordResponse);
  
            setPaymentSuccess("Payment successful and order created!");
            setPaymentError(null);
            dispatch(clearOrder());
            window.location.reload()
            navigate("/");
            // window.location.reload()
            // navigate("/order-success");
          } catch (error) {
            setPaymentError("An error occurred during payment verification.");
          }
        },
        prefill: {
          name: userData.firstName,
          email: userData.email,
          contact: userData.phoneNumber,
        },
        theme: {
          color: "#3399cc",
        },
      };
  
      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      console.error("Error creating Razorpay order:", error);
      setPaymentError("An error occurred during payment processing.");
    }
  };
  

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
        <h2 className="text-xl font-bold mb-6">Razorpay Payment (INR)</h2>
        <button
          onClick={handlePayment}
          className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-500"
        >
          Pay Now
        </button>
        {paymentError && <div className="text-red-500 mt-4">{paymentError}</div>}
        {paymentSuccess && <div className="text-green-500 mt-4">{paymentSuccess}</div>}
      </div>
    </div>
  );
};

export default PaymentForm;
