import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../utils/api"; // Adjust the import based on your project structure
import { toast } from "react-toastify";

const Categories = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);

  // Fetch categories from API
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await api.get(`${process.env.REACT_APP_API_URL}/api/categories`);
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories", error);
        toast.error("Failed to load categories");
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryClick = (category) => {
    navigate(`/products?category=${category._id}`); // Adjust the URL as needed
  };

  return (
    <div className="mt-6 px-4">
      <h2 className="text-2xl font-bold mb-4">Categories</h2>
      <div className="flex overflow-x-auto space-x-4 md:space-x-8 scrollbar-hide">
        {categories.map((category) => (
          <div
            key={category._id}
            className="flex-shrink-0 w-24 h-24 md:w-40 md:h-40 text-center flex flex-col items-center cursor-pointer"
            onClick={() => handleCategoryClick(category)}
          >
            <div className="w-24 h-24 md:w-40 md:h-40 rounded-full overflow-hidden bg-gray-200 mx-auto">
              <img
                src={category.image}
                alt={category.name}
                className="w-full h-full object-cover"
              />
            </div>
            <p className="mt-2 text-sm md:text-lg font-semibold whitespace-nowrap">{category.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Categories;
