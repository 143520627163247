import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  productDetails: [],
  quantity: 1,
  shippingAddress: {},
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setProductDetails: (state, action) => {
      console.log("state=>",state.productDetails);
      
    if(state?.productDetails)state?.productDetails?.push(action.payload); 
      
    },
    setQuantity: (state, action) => {
      state.quantity = action.payload;
    },
    setShippingAddress: (state, action) => {
      state.shippingAddress = action.payload;
    },
    clearOrder: (state) => {
      state.productDetails = null;
      state.quantity = 1;
      state.shippingAddress = {};
    }
  },
});

export const { setProductDetails, setQuantity, setShippingAddress, clearOrder } = orderSlice.actions;
export default orderSlice.reducer;
