// src/components/AdminPanel.jsx
import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import ManageProducts from './ManageProducts';
import ManageCategories from './ManageCategories';
import ManageOrders from './ManageOrders';

const AdminPanel = () => {
  return (
    <div>
      <h1>Admin Panel</h1>
      <Routes>
        <Route path="/products" element={<ManageProducts />} />
        <Route path="/categories" element={<ManageCategories />} />
        <Route path="/orders" element={<ManageOrders />} />
      </Routes>
    </div>
  );
};

export default AdminPanel;
