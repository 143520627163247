import React from "react";
import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  YouTube as YouTubeIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className="bg-gray-800 text-white p-8">
      <div className="container mx-auto flex flex-col md:flex-row justify-between">
        {/* Shop Location Details */}
        <div className="mb-6 md:mb-0">
          <h4 className="text-lg font-bold mb-4">Shop Location</h4>
          <p>Santhai Pettai Main Road</p>
          <p>Elampillai, Salem - 637502</p>
        </div>

        {/* Contact Details */}
        <div className="mb-6 md:mb-0">
          <h4 className="text-lg font-bold mb-4">Contact Us</h4>
          <p>Phone: +91 9385629808</p>
          <p>Email: sareesgrace@gmail.com</p>
        </div>

        {/* Quick Links */}
        <div className="mb-6 md:mb-0">
          <h4 className="text-lg font-bold mb-4">Quick Links</h4>
          <ul>
            <li>
              <button
                onClick={() => navigate("/")}
                className="hover:underline"
              >
                Home
              </button>
            </li>
            <li>
              <button
                onClick={() => navigate("/products")}
                className="hover:underline"
              >
                Products
              </button>
            </li>
            <li>
              <button
                onClick={() => navigate("/about")}
                className="hover:underline"
              >
                About Us
              </button>
            </li>
            <li>
              <button
                onClick={() => navigate("/contact")}
                className="hover:underline"
              >
                Contact
              </button>
            </li>
          </ul>
        </div>

        {/* Connect with Us (Social Media) */}
        <div>
          <h4 className="text-lg font-bold mb-4">Connect with Us</h4>
          <div className="flex space-x-4">
            <a href="https://www.facebook.com/sareesgrace" aria-label="Facebook">
              <FacebookIcon className="text-2xl hover:text-blue-600" />
            </a>
            <a href="https://www.instagram.com/sarees_grace" aria-label="Instagram">
              <InstagramIcon className="text-2xl hover:text-pink-500" />
            </a>
            <a href="https://www.youtube.com/@sareegrace" aria-label="YouTube">
              <YouTubeIcon className="text-2xl hover:text-red-600" />
            </a>
          </div>
        </div>
      </div>
      <div className="text-center mt-6">
        <p className="text-sm">© {new Date().getFullYear()} Sarees Grace. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
