// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import cartReducer from './features/cartSlice';
// import userReducer from './features/userSlice';
import wishlistReducer from './features/wishlistSlice';
import orderReducer from './features/orderSlice';

export const store = configureStore({
  reducer: {
    cart: cartReducer,
    // user: userReducer,
    wishlist: wishlistReducer,
    order: orderReducer,
  },
});

export default store;
