import React, { useEffect, useState } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SearchResults = () => {
  const query = useQuery().get('query');
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/products/search?query=${query}`);
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    if (query) {
      fetchProducts();
    }
  }, [query]);

  return (
    <div className="container mx-auto">
      <h2 className="text-2xl my-4">Search results for: {query}</h2>
      {products.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {products.map((product) => (
            <div key={product._id} className="border p-4">
              <img src={product.images[0].url} alt={product.name} className="w-full h-48 object-cover" />
              <h3 className="text-lg font-semibold mt-2">{product.name}</h3>
              <p className="text-gray-500">Price: {product.price}</p>
              <button
                onClick={() => navigate(`/products/${product._id}`)}
                className="bg-blue-500 text-white py-2 px-4 mt-2"
              >
                View Product
              </button>
            </div>
          ))}
        </div>
      ) : (
        <p>No products found for the query "{query}"</p>
      )}
    </div>
  );
};

export default SearchResults;
