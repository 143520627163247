import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../features/cartSlice';
import { setProductDetails, setQuantity } from '../features/orderSlice';
import axios from 'axios';
import ProductSection from './ProductSection';

const ProductDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams(); // Product ID from the URL
  const [product, setProduct] = useState(null); // Product details
  const [relatedProducts, setRelatedProducts] = useState([]); // Related products
  const [selectedImage, setSelectedImage] = useState(""); // Selected product image
  const [quantity, setQuantities] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Access the cart state from Redux
  const cart = useSelector((state) => state.cart.items);
  const isInCart = cart.some((cartItem) => cartItem._id === id); // Check if product is in the cart

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/${id}`);
        const productData = response.data;

        setProduct(productData); // Set product details
        setSelectedImage(productData.images[0]?.url); // Set initial selected image
        setLoading(false);
      } catch (err) {
        setError("Error fetching product details.");
        setLoading(false);
      }
    };

    const fetchRelatedProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/related/${id}`);
        setRelatedProducts(response.data.products);
      } catch (err) {
        console.error("Error fetching related products:", err);
      }
    };

    fetchProductDetails();
    fetchRelatedProducts();
  }, [id]);

  // Handle Add to Cart
  const handleAddToCart = () => {
    if (!isInCart) {
      const productWithSelectedImage = {
        _id: product._id,
        name: product.name,
        price: product.price,
        originalPrice: product.originalPrice,
        selectedImage,
        quantity
      };

      dispatch(addToCart(productWithSelectedImage)); // Add product to cart with selected image and details
    }
  };

  // Handle Buy Now
  const handleBuyNow = () => {
    const productWithSelectedImage = {
      _id: product._id,
      name: product.name,
      price: product.price,
      originalPrice: product.originalPrice,
      selectedImage,
      quantity
    };

    // Dispatch product details to the order state
    dispatch(setProductDetails(productWithSelectedImage));
    dispatch(setQuantity(quantity));

    // Redirect to shipping address or login
    if (localStorage.getItem('token')) {
      navigate("/shipping-address", { state: { product: productWithSelectedImage } });
    } else {
      navigate("/login", { state: { from: "/shipping-address", product: productWithSelectedImage } });
    }
  };

  // Quantity Control
  const handleIncrease = () => setQuantities(quantity + 1);
  const handleDecrease = () => {
    if (quantity > 1) setQuantities(quantity - 1);
  };

  // Handle Image Selection
  const handleImageSelect = (image) => setSelectedImage(image);

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <div className="px-4 py-8">
      {product && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left: Product Images */}
          <div>
            <img src={selectedImage} alt={product.name} className="w-full h-auto rounded-lg" />
            <div className="flex mt-4 space-x-4">
              {product.images.map((image, index) => (
                <img
                  key={index}
                  src={image.url}
                  alt={`Product thumbnail ${index + 1}`}
                  className={`w-20 h-20 object-cover rounded-lg cursor-pointer ${selectedImage === image.url ? 'border-2 border-blue-600' : ''}`}
                  onClick={() => handleImageSelect(image.url)}
                />
              ))}
            </div>
          </div>

          {/* Right: Product Details */}
          <div>
            <h1 className="text-3xl font-bold mb-4">{product.name}</h1>
            <div className="flex items-center mb-4">
              <p className="text-xl text-gray-600">₹{product.price}</p>
              <span className="text-sm text-gray-500 line-through ml-2">
                ₹{product.originalPrice}
              </span>
            </div>

            <p className="text-gray-700 mb-6">{product.description}</p>

            {/* Quantity Selector */}
            <div className="flex items-center mb-6">
              <button className="bg-gray-300 px-2 rounded-l" onClick={handleDecrease}>-</button>
              <span className="px-4">{quantity}</span>
              <button className="bg-gray-300 px-2 rounded-r" onClick={handleIncrease}>+</button>
            </div>

            {/* Add to Cart Button */}
            {/* <button
              className={`w-full py-2 rounded-lg mb-4 ${isInCart ? 'bg-gray-400 cursor-not-allowed' : 'bg-gray-600 text-white hover:bg-gray-500'}`}
              onClick={handleAddToCart}
              disabled={isInCart}
            >
              {isInCart ? 'Already in Cart' : 'Add to Cart'}
            </button> */}

            {/* Buy Now Button */}
            <button className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-500" onClick={handleBuyNow}>
              Buy Now
            </button>
          </div>
        </div>
      )}

      {/* Related Products Section */}
      {relatedProducts.length > 0 && (
        <ProductSection
          title="Related Products"
          products={relatedProducts}
          sectionType="related-products"
        />
      )}
    </div>
  );
};

export default ProductDetails;
