import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import api from "../../utils/api"

const ManageCategories = () => {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  // Fetch categories on component mount
  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await api.get(`${process.env.REACT_APP_API_URL}/api/categories`);
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories", error);
      toast.error("Failed to load categories");
    }
  };

  // Open modal to create a new category
  const openModal = () => {
    setNewCategory('');
    setDescription('');
    setImage(null);
    setIsEdit(false);
    setModalIsOpen(true);
  };

  // Open modal to edit an existing category
  const openEditModal = (category) => {
    setNewCategory(category.name);
    setDescription(category.description || '');
    setSelectedCategoryId(category._id);
    setIsEdit(true);
    setModalIsOpen(true);
  };

  // Handle image input change
  const handleImageChange = (e) => {
    setImage(e.target.files[0]); // Get the first selected file
  };

  // Submit form to create or edit a category
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create FormData to include image file and other fields
    const formData = new FormData();
    formData.append('name', newCategory);
    formData.append('description', description);
    if (image) {
      formData.append('image', image);
    }

    try {
      if (isEdit) {
        await api.put(`${process.env.REACT_APP_API_URL}/api/categories/${selectedCategoryId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        toast.success('Category updated successfully');
      } else {
        await api.post(`${process.env.REACT_APP_API_URL}/api/categories`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        toast.success('Category added successfully');
      }
      fetchCategories();
      setModalIsOpen(false);
    } catch (error) {
      console.error("Error saving category", error);
      toast.error("Failed to save category");
    }
  };

  // Delete a category
  const handleDelete = async (categoryId) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      try {
        await api.delete(`${process.env.REACT_APP_API_URL}/api/categories/${categoryId}`);
        toast.success('Category deleted successfully');
        fetchCategories();
      } catch (error) {
        console.error("Error deleting category", error);
        toast.error("Failed to delete category");
      }
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Manage Categories</h1>

      <button className="bg-green-500 text-white px-4 py-2 rounded mb-4" onClick={openModal}>
        Add Category
      </button>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border">
          <thead>
            <tr className="text-left bg-gray-100">
              <th className="py-2 px-4">Category Name</th>
              <th className="py-2 px-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category) => (
              <tr key={category._id} className="border-t">
                <td className="py-2 px-4">{category.name}</td>
                <td className="py-2 px-4">
                  <button className="bg-blue-500 text-white px-2 py-1 rounded mx-1" onClick={() => openEditModal(category)}>
                    Edit
                  </button>
                  <button className="bg-red-500 text-white px-2 py-1 rounded" onClick={() => handleDelete(category._id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal for creating/updating category */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        ariaHideApp={false}
        className="modal-content bg-white p-8 rounded-lg max-w-lg w-full mx-auto"
        overlayClassName="modal-overlay fixed inset-0 bg-black bg-opacity-50"
      >
        <h2 className="text-xl font-bold mb-4">{isEdit ? "Edit Category" : "Add Category"}</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            placeholder="Category Name"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            className="input-field w-full p-2 border rounded"
            required
          />
          <textarea
            placeholder="Category Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="input-field w-full p-2 border rounded"
            rows="3"
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="input-field w-full p-2 border rounded"
          />
          <div className="flex justify-end">
            <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">
              {isEdit ? "Update Category" : "Create Category"}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default ManageCategories;
