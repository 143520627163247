// features/cartSlice.js
import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [], // Stores products added to the cart
    count: 0,  // Total number of items in the cart
  },
  reducers: {
    addToCart: (state, action) => {
      const product = action.payload;
      const existingProduct = state.items.find(item => item._id === product._id);
      if (existingProduct) {
        existingProduct.quantity += 1;  // If the product exists, increase the quantity
      } else {
        state.items.push({ ...product, quantity: 1 }); // Add new product with quantity 1
      }
      state.count += 1;  // Increase total count of items in cart
    },
    removeFromCart: (state, action) => {
      const productId = action.payload;
      const product = state.items.find(item => item._id === productId);
      if (product) {
        state.count -= product.quantity; // Decrease total count by product's quantity
        state.items = state.items.filter(item => item._id !== productId); // Remove product
      }
    },
    updateCartQuantity: (state, action) => {
      const { productId, quantity } = action.payload;
      const product = state.items.find(item => item._id === productId);
      if (product) {
        const quantityDiff = quantity - product.quantity;
        product.quantity = quantity;
        state.count += quantityDiff;  // Update count based on new quantity
      }
    },
  },
});

export const { addToCart, removeFromCart, updateCartQuantity } = cartSlice.actions;
export default cartSlice.reducer;
