import React, { useState, useEffect } from "react";
import axios from "axios";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';  // Heart Icon (wishlist)
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';      // Cart Icon
import ProductSection from './ProductSection';

const BestSelling = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch Best Selling Products from the API
  useEffect(() => {
    const fetchBestSellingProducts = async () => {
      try {
        setLoading(true); // Set loading state
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/products?sortBy=bestSelling`);
        setProducts(response.data.products); // Set products from API response
        setLoading(false); // Loading is done
      } catch (error) {
        console.error("Error fetching best selling products:", error);
        setError("Failed to load best selling products.");
        setLoading(false);
      }
    };

    fetchBestSellingProducts(); // Call API when component mounts
  }, []);

  if (loading) {
    return <div className="text-center">Loading...</div>; // Show loading state
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>; // Show error message
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <ProductSection
        title="Best Selling"
        products={products} // Pass the fetched products here
        sectionType="best-selling"
      />
    </div>
  );
};

export default BestSelling;
