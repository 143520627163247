import axios from 'axios';

// Get the token from localStorage, or wherever you store it
const token = localStorage.getItem('token');

// Create an Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Use your API base URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to add the token to the headers for every request
api.interceptors.request.use(
  (config) => {
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // Add token to headers
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
