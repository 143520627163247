import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
// import Banner from "./components/Banner";
// import Categories from "./components/Categories";
// import NewArrivals from "./components/NewArrivals";
// import BestSelling from "./components/BestSelling";
import ProductDetails from "./components/ProductDetails";
import Login from "./components/Login";
import Signup from "./components/Signup";
import ShippingAddress from "./components/ShippingAddress";
import ForgetPassword from "./components/ForgetPassword";
import OrderSummary from "./components/OrderSummary";
import PaymentForm from './components/Payment'
import "./index.css"; // Import Tailwind and Slick CSS
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Footer from "./components/Footer";
import ProductList from "./components/ProductList";
import ViewAllProductsPage from './components/ViewAllProductsPage';
import WishlistPage from "./components/WishlistPage";
import Cart from "./components/Cart"
import StaticBanner from "./components/StaticBanner";
import AdminPanel from './components/AdminPanel/AdminPanel';
import ProductsPage from "./components/ProductsPage";
import NotFound from "./components/NotFound";
import SearchResults from './components/SearchResults';
import GoogleDriveImages from './components/GoogleDriveImages';
import Home from './components/Home'
const stripePromise = loadStripe("pk_test_51OMDoWGftRBWotxurYn2xLgifjNS66epW2jh6il7JNYVBRcdoWrGmG9Wfw2AFZwUAp5ZoC5Krg0BndMJ5huELVc600hMSZWsqJ");

function App() {
  console.log("BACK_END_URL =>",process.env.REACT_APP_API_URL);
  console.log("window.location.pathname =>",window.location.pathname);
  return (
    <Router>
      <div className="App">
        <Header />
        
        {/* <GoogleDriveImages /> */}
        <Routes>
          <Route
            path="/"
            element={
              <>
              <Home/>
                {/* <Banner />
                <Categories />

                <NewArrivals />
               
                <BestSelling />
                <StaticBanner/> */}
                {/* <Footer/> */}
              </>
            }
          />
          <Route path="/product/:id" element={<ProductDetails />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/shipping-address" element={<ShippingAddress />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/order-summary" element={<OrderSummary />} />
          <Route path="/category/:category" element={<ProductList />} />
          {/* <Route path="/products/view-all/new-arrivals" element={<NewArrivals />} />
          <Route path="/products/view-all/best-selling" element={<BestSelling />} /> */}
          <Route path="/products/view-all/:sectionType" element={<ViewAllProductsPage />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/payment" element={
            <Elements stripe={stripePromise}>
            <PaymentForm />
          </Elements>} />
          <Route path="/wishlist" element={<WishlistPage />} />
          <Route path="/cart" element={<Cart />} />
           {/* Admin Routes */}
           <Route path="/admin/*" element={<AdminPanel />} /> {/* This will handle all admin-relat routes */}
           <Route path="/search" element={<SearchResults />} />
           <Route path="*" element={<NotFound/>}/>
          
        </Routes>
      </div>
      <Footer/>
    </Router>
  );
}

export default App;
