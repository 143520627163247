import React,{useEffect} from 'react'
import Banner from "./Banner";
import Categories from "./Categories";
import NewArrivals from "./NewArrivals";
import BestSelling from "./BestSelling";
import StaticBanner from "./StaticBanner";

function Home() {
    useEffect(()=>{
console.log('render =>',)

    },[])
    return (
        <div>
            <Banner />
            <Categories />

            <NewArrivals />

            <BestSelling />
            <StaticBanner />
        </div>
    )
}

export default Home