import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PaymentIcon from '@mui/icons-material/Payment';

function StaticBanner() {
  return (
    <div className="bg-gray-100 py-8 px-4">
      <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 text-center">
        
        <div className="p-6 bg-white rounded-lg shadow-md">
          <LocalShippingIcon style={{ fontSize: 48 }} className="text-blue-500 mb-4" />
          <h3 className="text-xl font-semibold mb-2">Worldwide Shipping</h3>
          <p className="text-gray-600">We Ship Everywhere</p>
        </div>

        <div className="p-6 bg-white rounded-lg shadow-md">
          <CheckCircleIcon style={{ fontSize: 48 }} className="text-green-500 mb-4" />
          <h3 className="text-xl font-semibold mb-2">Quality Sarees</h3>
          <p className="text-gray-600">Handpicked Sarees</p>
        </div>

        <div className="p-6 bg-white rounded-lg shadow-md">
          <SupportAgentIcon style={{ fontSize: 48 }} className="text-yellow-500 mb-4" />
          <h3 className="text-xl font-semibold mb-2">Top-Notch Support</h3>
          <p className="text-gray-600">Get Superfast Customer Support</p>
        </div>

        <div className="p-6 bg-white rounded-lg shadow-md">
          <PaymentIcon style={{ fontSize: 48 }} className="text-purple-500 mb-4" />
          <h3 className="text-xl font-semibold mb-2">Secure Payments</h3>
          <p className="text-gray-600">All Payment Methods Accepted</p>
        </div>

      </div>
    </div>
  );
}

export default StaticBanner;
