import React from 'react';
import { useNavigate } from 'react-router-dom';
import ProductCard from './ProductCard'

const ProductSection = ({ title, products, sectionType }) => {
  const navigate = useNavigate();

  const handleViewAllClick = () => {
    // navigate(`/products/view-all/${sectionType}`);
    navigate(`/products`);
  };

  return (
    <div className="my-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">{title}</h2>
        {products.length>5&&<button
          className="text-blue-600 hover:underline"
          onClick={handleViewAllClick}
        >
          View All
        </button>}
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {products.slice(0, 8).map((product) => (
              <ProductCard key={product._id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default ProductSection;
