import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link, useNavigate } from "react-router-dom";
import { Drawer, List, ListItem, ListItemText, IconButton, Button } from "@mui/material";
import logo from '../assets/Saree_Grace.png';
import FavoriteIcon from '@mui/icons-material/Favorite';

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const wishlistItems = useSelector((state) => state.wishlist.wishlistItems);
  const wishlistCount = useSelector((state) => state.wishlist.count);
  const cartCount = useSelector((state) => state.cart.count);
  const user = useSelector((state) => state.user);

  const [showSearchBar, setShowSearchBar] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // State for search input

  const toggleSearchBar = () => {
    setShowSearchBar(!showSearchBar);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value); // Update the search query state
  };

  const handleSearchSubmit = () => {
    if (searchQuery.trim()) {
      navigate(`/products?search=${searchQuery}`); // Navigate to product search results page
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchSubmit(); // Trigger search on Enter key press
    }
  };

  const handleWishlistClick = () => {
    navigate('/wishlist');
  };

  const handleAvatarClick = () => {
    if (user?.isLoggedIn) {
      navigate('/profile');
    } else {
      navigate('/login');
    }
  };

  const handleLogout = () => {
    dispatch({ type: 'LOGOUT' });
    navigate('/login');
    localStorage.clear();
  };

  const navItems = [
    { label: 'Home', path: '/' },
    { label: 'Products', path: '/products' },
    { label: 'About', path: '/about' },
    { label: 'Contact', path: '/contact' },
    { label: 'Login', path: '/login' },
    
   
  ];
  let userData = JSON.parse(localStorage.getItem('user'))
      // console.log("userData =>",userData?.role);
  if(userData?.role === 'admin'){
    navItems.push({ label: 'Add Category', path: '/admin/categories' },{ label: 'Add Product', path: '/admin/products' },{ label: 'View Orders', path: '/admin/orders' })
  }
// console.log("navItems =>",navItems);

  return (
    <header className="bg-white shadow-md p-4">
      <div className="container mx-auto flex justify-between items-center">
        {/* Hamburger Menu for mobile view */}
        <div className="md:hidden">
          <IconButton onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
        </div>

        {/* Logo */}
        <Link to="/">
          <div className="w-auto">
            <img
              src={logo}
              alt="Saree Grace"
              className="h-8 lg:h-12 mx-auto md:mx-0 object-contain"
            />
          </div>
        </Link>

        {/* Search Bar for web view */}
        <div className="hidden md:flex w-1/3 items-center">
          <input
            type="text"
            placeholder="Search for products"
            className="w-full p-2 border border-gray-300 rounded-lg"
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress} // Trigger search on Enter key
          />
          <button
            className="p-2 ml-2 bg-blue-500 text-white rounded-lg"
            onClick={handleSearchSubmit} // Trigger search on button click
          >
            <SearchIcon />
          </button>
        </div>

        {/* Wishlist, Cart, and Avatar Icons */}
        <div className="w-1/3 md:w-auto flex justify-end space-x-4 items-center">
          {/* Search icon only for mobile view */}
          <div className="md:hidden">
            <SearchIcon onClick={toggleSearchBar} className="cursor-pointer" />
          </div>
          {/* Wishlist */}
          {/* <div className="relative cursor-pointer">
            <Link to="/wishlist">
              <FavoriteIcon />
              {wishlistCount > 0 && (
                <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs w-5 h-5 rounded-full flex items-center justify-center">
                  {wishlistCount}
                </span>
              )}
            </Link>
          </div> */}

          {/* Cart */}
          {/* <div className="relative cursor-pointer">
            <Link to="/cart">
              <ShoppingCartIcon />
              {cartCount > 0 && (
                <span className="absolute -top-2 -right-2 bg-blue-500 text-white text-xs w-5 h-5 rounded-full flex items-center justify-center">
                  {cartCount}
                </span>
              )}
            </Link>
          </div> */}

          {/* Avatar Icon and Logout */}
          <div className="hidden md:flex items-center space-x-2 cursor-pointer">
            {localStorage.getItem('token') ? (
              <>
                <Button variant="outlined" size="small" onClick={handleLogout}>
                  Logout
                </Button>
              </>
            ) : (
              <AccountCircleIcon onClick={handleAvatarClick} />
            )}
          </div>
        </div>
      </div>

      {/* Search Bar for mobile view */}
      {showSearchBar && (
        <div className="md:hidden mt-2 flex items-center">
          <input
            type="text"
            placeholder="Search for products"
            className="w-full p-2 border border-gray-300 rounded-lg"
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress} // Trigger search on Enter key
          />
          <button
            className="p-2 ml-2 bg-blue-500 text-white rounded-lg"
            onClick={handleSearchSubmit} // Trigger search on button click
          >
            <SearchIcon />
          </button>
        </div>
      )}

      {/* Drawer for mobile navigation */}
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List sx={{ width: 250 }}>
          {navItems.map((item) => (
            <ListItem
              button
              key={item.label}
              component={Link}
              to={item.path}
              onClick={toggleDrawer(false)}
            >
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </header>
  );
};

export default Header;
