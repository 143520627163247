// features/wishlistSlice.js
import { createSlice } from "@reduxjs/toolkit";

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState: {
    items: [],
    count: 0, // Initial count of wishlist items
  },
  reducers: {
    addToWishlist: (state, action) => {
      const product = action.payload;
      if (!state.items.find(item => item._id === product._id)) {
        state.items.push(product);
        state.count += 1; // Increase count
      }
    },
    removeFromWishlist: (state, action) => {
      const productId = action.payload;
      const index = state.items.findIndex(item => item._id === productId);
      if (index !== -1) {
        state.items.splice(index, 1);
        state.count -= 1; // Decrease count
      }
    },
  },
});

export const { addToWishlist, removeFromWishlist } = wishlistSlice.actions;
export default wishlistSlice.reducer;
