import React, { useState } from "react";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");

  const handleReset = (e) => {
    e.preventDefault();
    // Mock reset password logic
    console.log("Password reset for:", email);
    // Implement the actual reset password functionality here
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <form onSubmit={handleReset} className="bg-white p-8 rounded-lg shadow-lg w-96">
        <h1 className="text-2xl font-bold mb-6">Reset Password</h1>
        <input
          type="email"
          placeholder="Enter your email"
          className="w-full mb-6 p-2 border rounded"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-500">
          Send Reset Link
        </button>
      </form>
    </div>
  );
};

export default ForgetPassword;
