import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import api from '../../utils/api'

const ManageProducts = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]); // State for categories
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    price: "",
    originalPrice: "",
    category: "", // Store category ID here
    stock: "",
    description: "",
    images: [],
  });
  const [imageFiles, setImageFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);

  useEffect(() => {
    fetchProducts();
    fetchCategories(); // Fetch categories when component mounts
  }, []);

  // Fetch products
  const fetchProducts = async () => {
    try {
      const response = await api.get(`${process.env.REACT_APP_API_URL}/api/products`);
      setProducts(response.data.products);
    } catch (error) {
      console.error("Error fetching products", error);
    }
  };

  // Fetch categories
  const fetchCategories = async () => {
    try {
      const response = await api.get(`${process.env.REACT_APP_API_URL}/api/categories`); // Assuming your categories endpoint
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories", error);
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setImageFiles(files);

    // Create image previews
    const previews = files.map((file) => URL.createObjectURL(file));
    setImagePreviews(previews);
  };

  const openModal = () => {
    setFormData({
      name: "",
      price: "",
      originalPrice: "",
      category: "",
      stock: "",
      description: "",
      images: [],
    });
    setImageFiles([]);
    setImagePreviews([]);
    setIsEdit(false);
    setModalIsOpen(true);
  };

  const openEditModal = (product) => {
    setFormData({
      name: product.name,
      price: product.price,
      originalPrice: product.originalPrice,
      category: product.category,
      stock: product.stock,
      description: product.description,
      images: product.images || [],
    });
    setImageFiles([]);
    setImagePreviews([]);
    setIsEdit(true);
    setSelectedProductId(product._id);
    setModalIsOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataObj = new FormData();
      formDataObj.append("name", formData.name);
      formDataObj.append("price", formData.price);
      formDataObj.append("originalPrice", formData.originalPrice);
      formDataObj.append("category", formData.category); // Send selected category ID
      formDataObj.append("stock", formData.stock);
      formDataObj.append("description", formData.description);

      imageFiles.forEach((file) => formDataObj.append("images", file));

      if (isEdit) {
        await api.put(`${process.env.REACT_APP_API_URL}/api/products/${selectedProductId}`, formDataObj, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        toast.success("Product updated successfully");
      } else {
        await api.post(`${process.env.REACT_APP_API_URL}/api/products`, formDataObj, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        toast.success("Product created successfully");
      }
      fetchProducts();
      setModalIsOpen(false);
    } catch (error) {
      toast.error("Error saving product");
      console.error(error);
    }
  };

  const handleDelete = async (productId) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        await api.delete(`${process.env.REACT_APP_API_URL}/api/products/${productId}`);
        toast.success("Product deleted successfully");
        fetchProducts();
      } catch (error) {
        toast.error("Error deleting product");
        console.error("Error deleting product:", error);
      }
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Manage Products</h1>

      <button className="bg-green-500 text-white px-4 py-2 rounded mb-4" onClick={openModal}>
        Create Product
      </button>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border">
          <thead>
            <tr className="text-left bg-gray-100">
              <th className="py-2 px-4">Product Name</th>
              <th className="py-2 px-4">Category</th>
              <th className="py-2 px-4">Price</th>
              <th className="py-2 px-4">Stock</th>
              <th className="py-2 px-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product._id} className="border-t">
                <td className="py-2 px-4">{product.name}</td>
                <td className="py-2 px-4">{product.category}</td>
                <td className="py-2 px-4">₹{product.price}</td>
                <td className="py-2 px-4">{product.stock}</td>
                <td className="py-2 px-4">
                  <button className="bg-blue-500 text-white px-2 py-1 rounded mx-1" onClick={() => openEditModal(product)}>
                    Edit
                  </button>
                  <button className="bg-red-500 text-white px-2 py-1 rounded" onClick={() => handleDelete(product._id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal for creating/updating product */}
      <Modal
  isOpen={modalIsOpen}
  onRequestClose={() => setModalIsOpen(false)}
  ariaHideApp={false}
  className={`modal-content bg-white p-8 rounded-lg max-w-lg w-full mx-auto ${
    imagePreviews.length > 0 ? 'max-h-screen overflow-y-auto' : ''
  }`}
  overlayClassName="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
>
  <h2 className="text-xl font-bold mb-4">{isEdit ? "Update Product" : "Create Product"}</h2>
  <form onSubmit={handleSubmit} encType="multipart/form-data" className="space-y-4">
    <input
      type="text"
      placeholder="Product Name"
      value={formData.name}
      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
      className="input-field w-full p-2 border rounded"
    />
    <input
      type="number"
      placeholder="Price"
      value={formData.price}
      onChange={(e) => setFormData({ ...formData, price: e.target.value })}
      className="input-field w-full p-2 border rounded"
    />
    <input
      type="number"
      placeholder="Original Price"
      value={formData.originalPrice}
      onChange={(e) => setFormData({ ...formData, originalPrice: e.target.value })}
      className="input-field w-full p-2 border rounded"
    />
    <input
      type="number"
      placeholder="Stock"
      value={formData.stock}
      onChange={(e) => setFormData({ ...formData, stock: e.target.value })}
      className="input-field w-full p-2 border rounded"
    />
    <textarea
      placeholder="Description"
      value={formData.description}
      onChange={(e) => setFormData({ ...formData, description: e.target.value })}
      className="input-field w-full p-2 border rounded"
    />
    <input type="file" multiple onChange={handleFileChange} className="input-field w-full p-2 border rounded" />

    {/* Category Dropdown */}
    <select
      value={formData.category}
      onChange={(e) => setFormData({ ...formData, category: e.target.value })}
      className="input-field w-full p-2 border rounded"
    >
      <option value="">Select Category</option>
      {categories.map((category) => (
        <option key={category._id} value={category._id}>
          {category.name}
        </option>
      ))}
    </select>

    {/* Image Preview Section */}
    {imagePreviews.length > 0 && (
      <div className="flex flex-wrap mt-4 max-h-40 overflow-y-auto">
        {imagePreviews.map((preview, index) => (
          <img key={index} src={preview} alt="Preview" className="h-20 w-20 object-cover m-2" />
        ))}
      </div>
    )}

    <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">
      {isEdit ? "Update Product" : "Create Product"}
    </button>
  </form>
</Modal>

    </div>
  );
};

export default ManageProducts;
