import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateCartQuantity, removeFromCart } from "../features/cartSlice";
import { useNavigate } from 'react-router-dom';

const CartPage = () => {
  const cartItems = useSelector((state) => state.cart.items);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleQuantityChange = (productId, newQuantity) => {
    if (newQuantity > 0) {
      dispatch(updateCartQuantity({ productId, quantity: newQuantity }));
    }
  };
console.log("cartItems =>",cartItems);

  const handleRemoveItem = (productId) => {
    dispatch(removeFromCart(productId));
  };

  // Calculate subtotal
  const subtotal = cartItems.reduce((total, product) => total + (product.price * product.quantity), 0);

  const handlePlaceOrder = () => {
    if (localStorage.getItem('token')) {
      navigate("/shipping-address"); // Navigate to shipping address page
    } else {
      navigate("/login"); // Navigate to login page
    }
  };

  const handleProductClick = (productId) => {
    navigate(`/product/${productId}`); // Navigate to product detail page
  };

  return (
    <div className="cart-page p-4">
      <h1 className="text-2xl font-bold mb-4">My Cart</h1>

      {cartItems.length > 0 ? (
        <div className="grid grid-cols-1 gap-4">
          {cartItems.map((product) => (
            <div key={product._id} className="cart-item flex justify-between items-center bg-white p-4 rounded-lg shadow-md">
              <img
                src={product.selectedImage}
                alt={product.name}
                className="w-20 h-20 object-cover rounded-md cursor-pointer"
                onClick={() => handleProductClick(product._id)} // Navigate to product details on image click
              />
              <div className="cart-item-details flex-1 ml-4">
                <h3 className="text-lg font-semibold">{product.name}</h3>
                <p className="text-sm">₹{product.price}</p>
              </div>

              {/* Quantity Input */}
              <div className="quantity-selector flex items-center">
                <button
                  className="bg-gray-300 px-2 rounded-l"
                  onClick={() => handleQuantityChange(product._id, product.quantity - 1)}
                >
                  -
                </button>
                <input
                  type="text"
                  value={product.quantity}
                  readOnly
                  className="w-8 text-center"
                />
                <button
                  className="bg-gray-300 px-2 rounded-r"
                  onClick={() => handleQuantityChange(product._id, product.quantity + 1)}
                >
                  +
                </button>
              </div>

              {/* Remove Button */}
              <button
                className="bg-red-600 text-white py-1 px-3 rounded-lg ml-4"
                onClick={() => handleRemoveItem(product._id)}
              >
                Remove
              </button>
            </div>
          ))}
        </div>
      ) : (
        <p>Your cart is empty.</p>
      )}

      {/* Subtotal Section */}
      {cartItems.length > 0 && (
        <div className="mt-4">
          <h2 className="text-lg font-semibold">Subtotal: ₹{subtotal}</h2>
          <button
            className="bg-blue-600 text-white py-2 px-4 rounded-lg mt-4"
            onClick={handlePlaceOrder}
          >
            Place Order
          </button>
        </div>
      )}
    </div>
  );
};

export default CartPage;
