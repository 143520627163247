import React from 'react';
import { useParams } from 'react-router-dom';

// Mock data for products
const allProducts = {
  'new-arrivals': [
    { id: 1, name: 'Banarasi Silk', imageUrl: 'https://via.placeholder.com/150', price: 180 },
    { id: 2, name: 'Chiffon Saree', imageUrl: 'https://via.placeholder.com/150', price: 150 },
    { id: 3, name: 'Kanjivaram Silk', imageUrl: 'https://via.placeholder.com/150', price: 200 },
    { id: 4, name: 'Cotton Saree', imageUrl: 'https://via.placeholder.com/150', price: 120 },
    // Add more products as needed
  ],
  'best-selling': [
    { id: 1, name: 'Designer Saree', imageUrl: 'https://via.placeholder.com/150', price: 250 },
    { id: 2, name: 'Silk Saree', imageUrl: 'https://via.placeholder.com/150', price: 220 },
    { id: 3, name: 'Linen Saree', imageUrl: 'https://via.placeholder.com/150', price: 140 },
    { id: 4, name: 'Georgette Saree', imageUrl: 'https://via.placeholder.com/150', price: 190 },
    // Add more products as needed
  ],
};

const ViewAllProductsPage = () => {
  const { sectionType } = useParams();

  const products = allProducts[sectionType] || [];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">{sectionType.replace("-", " ")}</h1>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {products.map((product) => (
          <div key={product.id} className="bg-white border rounded-lg p-4">
            <img
              src={product.imageUrl}
              alt={product.name}
              className="w-full h-48 object-cover rounded-md"
            />
            <h3 className="mt-2 text-lg font-semibold">{product.name}</h3>
            <p className="text-sm text-gray-500">₹{product.price}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewAllProductsPage;
