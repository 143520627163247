import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "rc-slider";
import "rc-slider/assets/index.css"; // Slider styling
import ProductCard from '../components/ProductCard';
import { useLocation } from 'react-router-dom'; // For accessing query parameters

const ProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1); // Current page
  const [totalPages, setTotalPages] = useState(1); // Total number of pages
  const [filters, setFilters] = useState({
    sortBy: "latest",
    priceRange: [0, 5000],
  });

  const location = useLocation();

  // Extract category and search query from URL params
  const searchParams = new URLSearchParams(location.search);
  const category = searchParams.get('category'); // Fetch 'category' param from the URL
  const searchQuery = searchParams.get('search'); // Fetch 'search' param from the URL

  // Reset products and page whenever filters, category, or search query change
  useEffect(() => {
    setProducts([]);  // Clear existing products
    setPage(1);  // Reset page to 1
  }, [filters, category, searchQuery]);

  useEffect(() => {
    fetchProducts();  // Fetch products when page, filters, category, or search query change
  }, [page, filters, category, searchQuery]);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/products`, {
        params: { 
          page, 
          sortBy: filters.sortBy, 
          minPrice: filters.priceRange[0], 
          maxPrice: filters.priceRange[1],
          category: category || undefined, // Pass category if it exists
          search: searchQuery || undefined, // Pass search query if it exists
        },
      });
      setProducts(response.data.products); // Replace with new products based on the page
      setTotalPages(response.data.totalPages); // Get total pages from the API response
    } catch (error) {
      console.error("Error fetching products", error);
    }
  };

  const handleSortChange = (e) => {
    setPage(1); // Reset page to 1 when sorting changes
    setFilters({ ...filters, sortBy: e.target.value });
  };

  const handlePriceChange = (range) => {
    setPage(1); // Reset page to 1 when price range changes
    setFilters({ ...filters, priceRange: range });
  };

  const goToPreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const goToNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  return (
    <div className="container mx-auto p-4">
      {/* Filters Section */}
      <div className="flex flex-col sm:flex-row justify-between mb-4">
        {/* Sort Dropdown */}
        <div className="mb-4 sm:mb-0">
          <label htmlFor="sort" className="block font-medium mb-1">Sort By</label>
          <select
            id="sort"
            className="p-2 border rounded"
            value={filters.sortBy}
            onChange={handleSortChange}
          >
            <option value="latest">Latest</option>
            <option value="priceLowToHigh">Price: Low to High</option>
            <option value="priceHighToLow">Price: High to Low</option>
            <option value="newArrivals">New Arrivals</option>
          </select>
        </div>

        {/* Price Range Filter */}
        <div>
          <label className="block font-medium mb-1">Price Range: ₹{filters.priceRange[0]} - ₹{filters.priceRange[1]}</label>
          <Slider
            range
            min={0}
            max={5000}
            value={filters.priceRange}
            onChange={handlePriceChange}
          />
        </div>
      </div>

      {/* Product Listing */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {products.map((product) => (
          <ProductCard key={product._id} product={product} />
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-center items-center mt-4">
        <button
          className={`px-4 py-2 border rounded ${page === 1 ? "bg-gray-300 cursor-not-allowed" : "bg-blue-500 text-white"}`}
          onClick={goToPreviousPage}
          disabled={page === 1}
        >
          Previous
        </button>
        <span className="mx-4">Page {page} of {totalPages}</span>
        <button
          className={`px-4 py-2 border rounded ${page === totalPages ? "bg-gray-300 cursor-not-allowed" : "bg-blue-500 text-white"}`}
          onClick={goToNextPage}
          disabled={page === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ProductsPage;
