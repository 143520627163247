import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios"; // Import axios

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); // State for error message
  const navigate = useNavigate();
  const location = useLocation(); // To check from where the user was redirected

  // Get the redirect URL from location state if it exists
  const redirectTo = location.state?.from || "/"; // Default to home page if no redirect

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(""); // Clear previous error message

    try {
      // Make the API call to your login endpoint
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/login`, {
        email,
        password,
      });

      // Assuming the response contains a token or user data
      console.log("Logged in:", response.data);
      localStorage.setItem("user",JSON.stringify(response.data.user));
      // Save the token in localStorage or handle user session here
      localStorage.setItem("token", response.data.token); // Example of saving a token
      let userData = JSON.parse(localStorage.getItem('user'))
      console.log("userData =>",userData);
      
      // Redirect to the intended page or home page after successful login
      navigate(redirectTo); 
    } catch (error) {
      // Handle error
      console.error("Login error:", error);
      setError("Invalid email or password"); // Update error state
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <form onSubmit={handleLogin} className="bg-white p-8 rounded-lg shadow-lg w-96">
        <h1 className="text-2xl font-bold mb-6">Login</h1>
        {error && <p className="text-red-500 mb-4">{error}</p>} {/* Display error message */}
        <input
          type="email"
          placeholder="Email"
          className="w-full mb-4 p-2 border rounded"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          className="w-full mb-6 p-2 border rounded"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit" className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-500">
          Login
        </button>

        {/* Links for "Forget Password" and "Signup" */}
        <div className="mt-4 flex justify-between">
          <button
            type="button"
            onClick={() => navigate("/forget-password")}
            className="text-blue-600 hover:underline"
          >
            Forget Password?
          </button>
          <button
            type="button"
            onClick={() => navigate("/signup")}
            className="text-blue-600 hover:underline"
          >
            Signup
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
