import React from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

const OrderSummary = () => {
  const { productDetails: orderProductDetails, shippingAddress } = useSelector((state) => state.order);
  const navigate = useNavigate();
  
  console.log("productDetails =>", orderProductDetails);
  console.log("shippingAddress =>", shippingAddress);

  // Calculate price details based on multiple products
  const priceDetails = orderProductDetails?.reduce(
    (acc, product) => {
      const productPrice = product?.price || 0; // Default to 0 if price is missing
      const productQuantity = product?.quantity || 1; // Default to 1 if quantity is missing
      const productSubtotal = productPrice * productQuantity;

      acc.subtotal += productSubtotal;
      return acc;
    },
    {
      subtotal: 0,
      discount: 10, // Example discount applied to the entire order
      deliveryCharges: 50, // Example delivery charge for the entire order
      total: 0
    }
  );

  // Add discount and delivery charges to total
  if(priceDetails)
  priceDetails.total = priceDetails?.subtotal - priceDetails?.discount + priceDetails?.deliveryCharges;

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(amount);
  };

  const handleProceedToPayment = () => {
    navigate("/payment");
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-3xl">
        <h1 className="text-2xl font-bold mb-6 text-gray-700">Order Summary</h1>

        {/* Delivery Details */}
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2 text-gray-600">Delivery Details</h2>
          <div className="text-gray-600">
            <p>{shippingAddress?.name}</p>
            <p>{shippingAddress?.address}</p>
            <p>{`${shippingAddress?.city}, ${shippingAddress?.state}, ${shippingAddress?.country} - ${shippingAddress?.pincode}`}</p>
            <p>Phone: {shippingAddress?.phoneNumber}</p>
          </div>
        </div>

        {/* Product Details */}
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2 text-gray-600">Product Details</h2>
          {orderProductDetails?.map((product, index) => (
            <div key={index} className="flex justify-between mb-2 text-gray-600">
              <span>{product?.name} (x{product?.quantity || 1})</span>
              <span>{formatCurrency((product?.price || 0) * (product?.quantity || 1))}</span>
            </div>
          ))}
        </div>

        {/* Price Details */}
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2 text-gray-600">Price Details</h2>
          <div className="flex justify-between mb-2 text-gray-600">
            <span>Subtotal</span>
            <span>{formatCurrency(priceDetails?.subtotal)}</span>
          </div>
          <div className="flex justify-between mb-2 text-gray-600">
            <span>Discount</span>
            <span>-{formatCurrency(priceDetails?.discount)}</span>
          </div>
          <div className="flex justify-between mb-2 text-gray-600">
            <span>Delivery Charges</span>
            <span>{formatCurrency(priceDetails?.deliveryCharges)}</span>
          </div>
          <div className="flex justify-between font-bold text-gray-700 mb-2">
            <span>Total</span>
            <span>{formatCurrency(priceDetails?.total)}</span>
          </div>
        </div>

        {/* Proceed to Payment Button */}
        <button
          onClick={handleProceedToPayment}
          className="w-full bg-green-600 text-white py-2 rounded-lg hover:bg-green-500 transition duration-300"
        >
          Proceed to Payment
        </button>
      </div>
    </div>
  );
};

export default OrderSummary;
