import React from "react";
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import banner1 from '../assets/saree_grace_slider_01.webp';
import banner2 from '../assets/saree_grace_slider_02.webp';

// Custom next and previous arrow components (optional)
const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "gray", right: '10px' }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "gray", left: '10px', zIndex: 1 }}
      onClick={onClick}
    />
  );
};

const Banner = () => {
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  // Navigate to products page
  const navigateToProducts = () => {
    navigate('/products');
  };

  return (
    <div className="w-full mx-auto mt-4">
      <Slider {...settings}>
        <div onClick={navigateToProducts} className="cursor-pointer">
          <img
            src={banner1}
            alt="Banner 1"
            className="w-full h-48 md:h-96 object-cover"
          />
        </div>
        <div onClick={navigateToProducts} className="cursor-pointer">
          <img
            src={banner2}
            alt="Banner 2"
            className="w-full h-48 md:h-96 object-cover"
          />
        </div>
      </Slider>
    </div>
  );
};

export default Banner;
