// src/components/ProductList.js

import React, { useState } from "react";
import { useParams} from "react-router-dom";
import ProductCard from './ProductCard'; 

const products = [
  { id: 1, name: "Saree 1", price: 1000,originalPrice: 2500 , category: "Sarees", imageUrl: "https://via.placeholder.com/150" },
  { id: 2, name: "Saree 2", price: 1500, originalPrice: 2500 ,category: "Sarees", imageUrl: "https://via.placeholder.com/150" },
  { id: 3, name: "Lehenga 1", price: 2000,originalPrice: 2500 , category: "Lehengas", imageUrl: "https://via.placeholder.com/150" },
  { id: 4, name: "Lehenga 2", price: 2500,originalPrice: 2500 , category: "Lehengas", imageUrl: "https://via.placeholder.com/150" },
  { id: 5, name: "Salwar Kameez 1", price: 1200,originalPrice: 2500 , category: "Salwar Kameez", imageUrl: "https://via.placeholder.com/150" },
  { id: 6, name: "Kurtis 1", price: 800,originalPrice: 2500 , category: "Kurtis", imageUrl: "https://via.placeholder.com/150" },
  // Add more products as needed
];

const ProductList = () => {
  const { category } = useParams(); // Get the category from the URL
  const [sortOption, setSortOption] = useState("latest");

  const filteredProducts = products.filter((product) => product.category === category);

  const sortedProducts = [...filteredProducts].sort((a, b) => {
    if (sortOption === "latest") {
      return b.id - a.id; // Assuming the latest products have higher IDs
    } else if (sortOption === "lowToHigh") {
      return a.price - b.price;
    } else if (sortOption === "highToLow") {
      return b.price - a.price;
    }
    return 0;
  });

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Products in {category}</h2>

      {/* Filter Options */}
      <div className="mb-4">
        <label className="mr-2">Sort by:</label>
        <select value={sortOption} onChange={handleSortChange} className="border p-1 rounded">
          <option value="latest">Latest Products</option>
          <option value="lowToHigh">Price: Low to High</option>
          <option value="highToLow">Price: High to Low</option>
        </select>
      </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {sortedProducts.map(product => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
      
    </div>
  );
};

export default ProductList;
