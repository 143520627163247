import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart } from "../features/cartSlice";
import { setProductDetails, setQuantity } from '../features/orderSlice';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

const ProductCard = ({ product }) => {
  const [isWishlisted, setIsWishlisted] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.items); // Get cart items from Redux

  const isProductInCart = cart.some(item => item._id === product._id); // Check if product is in the cart

  const handleCartClick = (product) => {
    if (isProductInCart) {
      alert("This product is already in your cart!"); // Notify user
    } else {
      // dispatch(setProductDetails(product));
      // dispatch(addToCart(product)); // Add to cart
    }
  };

  const handleWishlistClick = () => {
    setIsWishlisted(!isWishlisted); // Toggle wishlist status
  };

  const handleProductClick = () => {
    navigate(`/product/${product._id}`); // Navigate to product details
  };

  return (
    <div className="product-card bg-white border rounded-lg shadow-md p-4">
      <img
        src={product?.images.length && product?.images[0].url}
        alt={product.name}
        onClick={handleProductClick}
        className="cursor-pointer w-full h-48 object-cover rounded-md"
      />
      <div className="mt-2">
        <h3 className="text-md font-semibold">{product.name}</h3>

        {/* Price Display */}
        <div className="mt-2">
          <span className="text-lg font-bold text-gray-900">₹{product.price}</span>{" "}
          {product.originalPrice && (
            <span className="text-sm text-gray-500 line-through ml-2">
              ₹{product.originalPrice}
            </span>
          )}
        </div>

        {/* Wishlist Icon */}
        {/* <div onClick={handleWishlistClick} className="wishlist-icon cursor-pointer mt-2">
          {isWishlisted ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon />}
        </div> */}

        {/* Cart Button */}
        {/* <div className="cart-button mt-2">
          {isProductInCart ? (
            <button className="bg-red-600 text-white py-2 px-4 rounded-lg cursor-not-allowed" disabled>
              Added to Cart
            </button>
          ) : (
            <button onClick={handleCartClick(product)} className="bg-blue-600 text-white py-2 px-4 rounded-lg">
              Add to Cart
            </button>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default ProductCard;
