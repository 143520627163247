import React, { useState, useEffect } from 'react';
import axios from 'axios';
import api from '../../utils/api';

const ManageOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
   

    fetchOrders();
  }, []);
  const fetchOrders = async () => {
    try {
      const { data } = await api.get(`${process.env.REACT_APP_API_URL}/api/orders`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      });
      setOrders(data);
      setLoading(false);
    } catch (err) {
      setError(err.response ? err.response.data.message : err.message);
      setLoading(false);
    }
  };
  const updateOrderStatus = async (orderId, newStatus) => {
    try {
      await api.put(`${process.env.REACT_APP_API_URL}/api/orders/${orderId}`, { orderStatus: newStatus }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      });
      // Fetch orders again to refresh the list
      fetchOrders();
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="order-list">
      <h1 className="text-2xl font-bold mb-4">Order List</h1>
      <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse border border-gray-200">
          <thead>
            <tr>
              <th className="border border-gray-300 p-2">First Name</th>
              <th className="border border-gray-300 p-2">Phone</th>
              <th className="border border-gray-300 p-2">Order Status</th>
              <th className="border border-gray-300 p-2">Product Image</th>
              <th className="border border-gray-300 p-2">Quantity</th>
              <th className="border border-gray-300 p-2">Price</th>
              <th className="border border-gray-300 p-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {orders.map(order => (
              order.orderItems.map(item => (
                <tr key={item._id}>
                  <td className="border border-gray-300 p-2">{order.userId.firstName}</td>
                  <td className="border border-gray-300 p-2">{order.userId.phoneNumber}</td>
                  <td className="border border-gray-300 p-2">
                    <select
                      value={order.orderStatus}
                      onChange={(e) => updateOrderStatus(order._id, e.target.value)}
                      className="border rounded p-1"
                    >
                      <option value="pending">Pending</option>
                      <option value="completed">Completed</option>
                      <option value="canceled">Canceled</option>
                      {/* Add more status options as needed */}
                    </select>
                  </td>
                  <td className="border border-gray-300 p-2">
                    <img src={item.image} alt={item.name} className="w-20 h-20 object-cover" />
                  </td>
                  <td className="border border-gray-300 p-2">{item.quantity}</td>
                  <td className="border border-gray-300 p-2">₹{item.price}</td>
                  <td className="border border-gray-300 p-2">
                    {order.orderStatus === 'completed'?'Completed':
                      <button 
                      onClick={() => updateOrderStatus(order._id, 'completed')} 
                      className="bg-blue-500 text-white p-1 rounded"
                    >
                      Mark as Complete
                    </button>
                    }
                    
                  </td>
                </tr>
              ))
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageOrders;
