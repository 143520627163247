import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Import axios

const Signup = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    password: "",
  });
  const [error, setError] = useState(""); // State for error message
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setError(""); // Clear previous error message

    try {
      // Make the API call to your signup endpoint
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/register`, formData);

      // Assuming the response contains a success message or user data
      console.log("Signed up:", response.data);
      
      // Redirect to the shipping address page after successful signup
      navigate("/shipping-address");
    } catch (error) {
      // Handle error
      console.error("Signup error:", error);
      setError("Failed to sign up. Please check your details."); // Update error state
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <form onSubmit={handleSignup} className="bg-white p-8 rounded-lg shadow-lg w-96">
        <h1 className="text-2xl font-bold mb-6">Signup</h1>
        {error && <p className="text-red-500 mb-4">{error}</p>} {/* Display error message */}

        <input
          type="text"
          name="firstName"
          placeholder="First Name"
          className="w-full mb-4 p-2 border rounded"
          value={formData.firstName}
          onChange={handleChange}
        />
        <input
          type="text"
          name="lastName"
          placeholder="Last Name"
          className="w-full mb-4 p-2 border rounded"
          value={formData.lastName}
          onChange={handleChange}
        />
        <input
          type="tel"
          name="phoneNumber"
          placeholder="Phone Number"
          className="w-full mb-4 p-2 border rounded"
          value={formData.phoneNumber}
          onChange={handleChange}
        />
        <input
          type="email"
          name="email"
          placeholder="Email (required)"
          className="w-full mb-4 p-2 border rounded"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          className="w-full mb-6 p-2 border rounded"
          value={formData.password}
          onChange={handleChange}
        />

        <button type="submit" className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-500">
          Signup
        </button>
      </form>
    </div>
  );
};

export default Signup;
